import { FormsModule } from '@angular/forms';
import { MbscModule } from 'lib/@mobiscroll/angular';
import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy, iosTransitionAnimation } from '@ionic/angular';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HTTP_INTERCEPTORS, HttpBackend, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { QpassCoreModule } from '@q-pass/core';
import { QpassUserModule } from '@q-pass/user';
import { NgHttpCachingModule, NgHttpCachingStrategy } from 'ng-http-caching';
import { ServiceWorkerModule } from '@angular/service-worker';
import { LoggerModule } from  'ngx-logger';
import { AuthInterceptor } from "./interceptors/auth.interceptor";
import { RequestIdInterceptor } from "./interceptors/request-id.interceptor";
import { NativeGeocoder } from "@awesome-cordova-plugins/native-geocoder/ngx";
import { NgxStripeModule } from "ngx-stripe";

export function HttpLoaderFactory(_httpBackend: HttpBackend) {
  return new MultiTranslateHttpLoader(_httpBackend, [
    {prefix: './assets/i18n/'},
    {prefix: '/qpass-assets/i18n/'}
  ]);
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    FormsModule,
    MbscModule,
    BrowserModule,
    BrowserAnimationsModule,
    IonicModule.forRoot({
      navAnimation: iosTransitionAnimation,
      mode: 'ios'
    }),
    NgxStripeModule.forRoot(environment.stripeConfiguration.pub_key),
    QpassCoreModule.forRoot(
      environment.firebaseConfiguration,
      environment.QpassConfiguration,
      environment.datadogConfiguration
    ),
    QpassUserModule.forRoot(
      environment.QpassConfiguration
    ),
    NgHttpCachingModule.forRoot({
      lifetime: 60 * 1000, // cache expire after 60 seconds
      allowedMethod: ['GET'], // alllow only GET requests to be cached
      cacheStrategy: NgHttpCachingStrategy.DISALLOW_ALL // allow only requests explicitly specified to be cached

  }),
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpBackend]
      }
    }),
    LoggerModule.forRoot(environment.logging),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide : HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi   : true,
    },
    {
      provide : HTTP_INTERCEPTORS,
      useClass: RequestIdInterceptor,
      multi   : true,
    },
    NativeGeocoder
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
